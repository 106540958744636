import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { initSW } from "./background.js";
initSW();

Vue.config.productionTip = false;

const cfg = {
  client_id:
    "509167106205-dqqu64t7391ll6li3stvktsgband8h65.apps.googleusercontent.com",
  scope: "profile email",
  apiKey: "AIzaSyD3oiUFgeQLOYbk72z8ajRv3ADBVka893s",
  firebase: {
    apiKey: "AIzaSyD3oiUFgeQLOYbk72z8ajRv3ADBVka893s",
    authDomain: "getshot.firebaseapp.com",
    projectId: "getshot",
    storageBucket: "getshot.appspot.com",
    messagingSenderId: "509167106205",
    appId: "1:509167106205:web:25e0e97317eb2ed6cd7c74",
    databaseURL: "https://getshot-default-rtdb.firebaseio.com/",
  },
  apis: {
    drive: "v3",
    calendar: "v3",
    people: "v1",
  },
};

new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
    this.$store.dispatch("gapi/init", cfg);
  },
}).$mount("#app");
