import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import {
  getAuth,
  signInWithCredential,
  GoogleAuthProvider,
} from "firebase/auth";

const DISCOVERY_DOC =
  "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest";

const SCOPES = "https://www.googleapis.com/auth/drive.file";

const parseJwt = (payload) => {
  return (
    (payload &&
      JSON.parse(
        decodeURIComponent(
          window
            .atob(payload.split(".")[1].replace(/-/g, "+").replace(/_/g, "/"))
            .split("")
            .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
            .join("")
        )
      )) ||
    null
  );
};

// https://developers.google.com/drive/api/v3/reference/files
const gdrive = {
  /*
  this.$store.dispatch("gapi/requestAccessToken", "https://www.googleapis.com/auth/drive.metadata.readonly").then(async (r) => {
    gdrive.list({
      'pageSize': 10,
      'fields': 'files(id, name)',
    }).then((response) => {
      const files = response.result.files;
      if (!files || files.length == 0) {
        document.getElementById('content').innerText = 'No files found.';
        return;
      }
      const output = files.reduce(
        (str, file) => `${str}${file.name} (${file.id})\n`,
        'Files:\n');
      console.log(output);
    })
  });
  */
  list(options) {
    // scopes can be aditioned requested or all at once
    // q = "name = 'Project #32'"
    // q = "name = 'test.json'"
    // q = "'appDataFolder' in parents"
    // ou use { 'pageSize': 10, 'fields': 'files(id, name)' }
    return window.gapi.client.drive.files.list(options);
  },
  get(id, type) {
    if (!id || !(window.gapi.client.drive || null)) {
      return new Promise(function(resolve) {
        resolve({
          id: id ? "valid" : "invalid",
          drive: window.gapi.client.drive ? "valid" : "invalid",
        });
      });
    }
    let opt = {
      fileId: id,
    };
    let request = null;
    if (type == "docs") {
      request = window.gapi.client.drive.files.export;
    } else {
      request = window.gapi.client.drive.files.get;
      if (type == "content") {
        opt.alt = "media";
      }
    }
    return request(opt);
  },
  put(file) {
    // https://developers.google.com/drive/api/v3/reference/files/create
    return new Promise((resolve) => {
      const boundary = "-------314159265358979323846";
      const delimiter = "\r\n--" + boundary + "\r\n";
      const close_delim = "\r\n--" + boundary + "--";
      const contentType = "application/json";
      var metadata = {
        name: file.name,
        mimeType: contentType,
      };

      let options = {
        method: file.id ? "PATCH" : "POST",
        path: "/upload/drive/v3/files" + (file.id ? "/" + file.id : ""),
        params: {},
      };

      if (!file.id) {
        metadata.parents = [file.parent || ""];
      }

      if (file.data) {
        options.params.uploadType = "multipart";
        options.headers = {
          "Content-Type": 'multipart/related; boundary="' + boundary + '"',
        };
        options.body =
          delimiter +
          "Content-Type: application/json\r\n\r\n" +
          JSON.stringify(metadata) +
          delimiter +
          "Content-Type: " +
          contentType +
          "\r\n\r\n" +
          JSON.stringify(file.data) +
          close_delim;
      }
      window.gapi.client.request(options).execute((ret) => {
        resolve(ret);
      });
    });
  },
  del(id) {
    return window.gapi.client.drive.files.delete({ fileId: id });
  },
  upload(payload) {
    return new Promise((resolve) => {
      var file = new Blob([payload.content], { type: "text/plain" });
      var metadata = {
        name: payload.name, // Filename at Google Drive
        mimeType: payload.mimetype, // mimeType at Google Drive
      };

      var form = new FormData();
      form.append(
        "metadata",
        new Blob([JSON.stringify(metadata)], { type: "application/json" })
      );
      form.append("file", file);
      fetch(
        "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id",
        {
          method: "POST",
          headers: new Headers({
            Authorization: "Bearer " + gapi.auth.getToken().access_token,
          }),
          body: form,
        }
      )
        .then((res) => res.json())
        .then((val) => resolve(val));
    });
  },
};

function Firebase(config) {
  this.config = config;
  this.app = initializeApp(config);
  this.auth = getAuth(this.app);
  this.user = null;
  this.accessToken = null;
  //=================
  this.signIn = (credential) => {
    signInWithCredential(this.auth, GoogleAuthProvider.credential(credential))
      .then((result) => {
        console.log(result);
        this.user = result.user;
        this.accessToken = this.user.accessToken;
        if (this.config.databaseURL) {
          console.log(this);
          this.db = getDatabase(this.app);
        }
      })
      .catch((error) => {
        console.error("firebase error", error);
      });
  };
  this.signOut = () => {
    this.app.auth().signOut();
  };
}

const initialState = () => ({
  ready: false,
  config: null,
  credential: null,
  interactiveLogin: false,
  client: null,
  scopes: [],
  accessToken: ""
});

const mutations = {
  RESET(state) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  CONFIG(state, value) {
    state.config = value;
  },
  READY(state, value) {
    state.ready = value;
  },
  CREDENTIAL(state, value) {
    state.credential = value;
  },
  ACCESS_TOKEN(state, value) {
    state.accessToken = value;
  },
  INTERACTIVE_LOGIN(state, value) {
    state.interactiveLogin = value;
  },
};

const getters = {
  isReady(state) {
    return state.ready;
  },
  isLoggedIn(state) {
    return state.credential;
  },
  config(state) {
    return {
      client_id: CLIENT_ID,
      api_key: API_KEY,
    };
  },
  credential(state) {
    return parseJwt(state.credential);
  },
  interactiveLogin(state) {
    return state.interactiveLogin;
  },
  accessToken(state) {
    return state.accessToken;
  }
};

// https://developers.google.com/identity/gsi/web/reference/js-reference
// https://developers.google.com/identity/oauth2/web/guides/use-token-model

const actions = {
  init(context, config) {
    let self = this;
    let services = { gis: false, gapi: false };

    context.commit("CONFIG", config);

    const validateServices = () => {
      if (!services.gis || !services.gapi) return;
      context.commit("READY", true);
      context.state.client = google.accounts.oauth2.initTokenClient({
        client_id: config.client_id,
        scope: config.scope,
        callback: null,
      });
      google.accounts.id.initialize({
        client_id: config.client_id,
        auto_select: true,
        callback: (response) => {
          if (response) {
            if (config.firebase) {
              self.firebase = new Firebase(config.firebase);
              self.firebase.signIn(response.credential);
            }
            context.commit("CREDENTIAL", response.credential);
          }
          context.dispatch("requestAccessToken").then((r) => {
            context.commit("ACCESS_TOKEN", r);

          });
        },
      });
      google.accounts.id.prompt((notification) => {
        // console.log(notification.getDismissedReason());
        if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
          context.commit("INTERACTIVE_LOGIN", true);
        }
      });
    };

    const gasLoaded = async () => {
      await window.gapi.client.init({
        apiKey: config.api_key,
        discoveryDocs: [DISCOVERY_DOC],
      });
      services.gapi = true;
      validateServices();
    };

    const gisLoaded = async () => {
      services.gis = true;
      validateServices();
    };

    const inject_api = async () => {
      if (window.gapi) {
        window.gapi.load("client", gasLoaded);
      } else {
        let script = document.createElement("script");
        script.setAttribute("src", "https://apis.google.com/js/api.js");
        script.setAttribute("type", "text/javascript");
        script.onload = () => {
          window.gapi.load("client", gasLoaded);
        };
        script.onerror = () => {
          console.log("error api");
        };
        document.getElementsByTagName("head")[0].appendChild(script);
      }
    };

    const inject_gsi = async () => {
      if (!window.gapi) {
        let script = document.createElement("script");
        script.setAttribute("src", "https://accounts.google.com/gsi/client");
        script.setAttribute("type", "text/javascript");
        script.onload = () => {
          // window.gapi.load("client", gisLoaded);
          gisLoaded();
        };
        script.onerror = () => {
          console.log("error gsi");
        };
        document.getElementsByTagName("head")[0].appendChild(script);
      }
    };

    inject_api();
    inject_gsi();
  },

  logout(context) {
    const token = window.gapi.client.getToken();
    if (token !== null) {
      google.accounts.oauth2.revoke(token.access_token);
      gapi.client.setToken("");
      context.commit("ACCESS_TOKEN", null);
      context.commit("CREDENTIAL", null);
      // TODO: redirect user

      // document.getElementById('content').style.display = 'none';
      // document.getElementById('content').innerHTML = '';
      // document.getElementById('authorize_button').value = 'Authorize';
      // document.getElementById('signout_button').style.visibility = 'hidden';
    }
  },

  requestAccessToken(context, scope) {
    return new Promise((resolve, reject) => {
      if (!context.state.ready) {
        reject("not ready");
        return;
      }
      const token = gapi.client.getToken();
      if (token && token.scope && token.scope.indexOf(scope) >= 0) {
        resolve(token);
        return;
      }
      if (context.state.client.callback) {
        context.state.client.requestAccessToken({ scope: scope });
      } else {
        context.state.client.callback = (access_token) => {
          if (access_token) {
            resolve(access_token);
          } else {
            reject("invalid access token");
          }
        };
        context.state.client.requestAccessToken({ prompt: "" });
      }
    });
  },
  async uploadFile(context, payload) {
    return gdrive.upload(payload);
  },
};

export default {
  namespaced: true,
  state: initialState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};

export { gdrive };
